<template>
  <!-- 首页 -->
  <div class="padding24 color_white box">
    <div class="banner">
      <img
        src="https://files.sheguvip.com/pre/resource/static/png/20220412/1513762936919822336/bg_welcome.png"
      />
      <!-- <div>欢迎使用xxx管理后台</div> -->
    </div>
    <div class="copyright">
      ©此网站由广州设谷科技有限公司进行维护 粤ICP备2022021290号-1
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  background: #fafafa !important;
  position: relative;
  .banner {
    width: 100%;
    height: 96%;
    img {
      width: 600px;
      margin-left: calc(50% - 300px);
    }
    div {
      margin-top: 30px;
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #222;
      line-height: 50px;
      text-align: center;
    }
  }
  .copyright {
    text-align: center;
    color: #999999;
  }
}
</style>
